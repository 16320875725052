import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../views/home"),
  },
  /*  {
    path: "/index2",
    component: () => import("../views/index2"),
  },
  {
    path: "/index3",
    component: () => import("../views/index3"),
  },
  {
    path: "/index4",
    component: () => import("../views/index4"),
  },
  {
    path: "/index5",
    component: () => import("../views/index5"),
  },
  {
    path: "/index6",
    component: () => import("../views/index6"),
  }, */
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
