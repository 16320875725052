/* eslint-disable */
const localizedTexts = {
  "it-IT": {
    navbar: {
      about_us: "Azienda",
      services: "Servizi",
      philosophy: "Filosofia",
      technologies: "Tecnologie",
      partners: "I nostri clienti",
      contact_us: "Contatti",
    },

    figcaption: {
      tigullioWeb: "UX/UI Web sviluppata per Tigullio Carni",
      tigullioMobile: "UX/UI Mobile sviluppata per Tigullio Carni",
      technosistemHMI: "UX/UI HMI per Tecnosystem",
    },

    tooltip: {
      language: "Cambia lingua",
      theme: "Cambia tema",
    },

    header: {
      header_subtitle:
        "Trasformiamo la complessità dell'operatività aziendale in valore aggiunto digitale. Siamo sempre alla ricerca di nuove sfide.",
      header_quote:
        "Le fondamenta della nostra azienda fanno di noi ciò che siamo.",
    },

    ux_ui: {
      ux_ui_title: "IL RUOLO ESSENZIALE DELL'ESPERIENZA UTENTE",
      ux_ui_content_1:
        "L’importanza di utilizzare un servizio di UX/UI (User Experience/User Interface) per lo sviluppo di un’applicazione digitale è fondamentale per diversi motivi:",
      ux_ui_content_2:
        "<b>Esperienza Utente Migliorata</b>: Un buon design <b>UX/UI</b> può migliorare significativamente l’<b>esperienza dell’utente</b>. Un’interfaccia intuitiva e facile da usare può rendere l’uso dell’applicazione più piacevole e meno frustrante per l’utente.",
      ux_ui_content_3:
        "<b>Aumento dell’Engagement</b>: Un design UX/UI efficace può aumentare l’<b>engagement</b> dell’utente. Se un utente trova un’applicazione facile da usare e piacevole, è più probabile che la utilizzi regolarmente e per periodi di tempo più lunghi.",
      ux_ui_content_4:
        "<b>Conversioni più Alte</b>: Un buon design UX/UI può portare a <b>tassi di conversione</b> più alti. Se un utente può facilmente navigare attraverso un’applicazione e compiere le azioni desiderate, è più probabile che compia azioni che portano a conversioni, come fare un acquisto o iscriversi a un servizio.",
      ux_ui_content_5:
        "<b>Differenziazione dalla Concorrenza</b>: Un design UX/UI di alta qualità può aiutare un’applicazione a distinguersi dalla <b>concorrenza</b>. Se un’applicazione offre un’esperienza utente superiore rispetto alle applicazioni concorrenti, può diventare la scelta preferita degli utenti.",
      ux_ui_content_6:
        "<b>Riduzione dei Costi a Lungo Termine</b>: Investire in un buon design UX/UI all’inizio dello sviluppo dell’applicazione può ridurre i <b>costi a lungo termine</b>. Un design ben pensato può ridurre la necessità di costose modifiche o correzioni in seguito.",
    },

    about: {
      about_subtitle_1: "INNOVAZIONE TECNOLOGICA",
      about_content_1:
        "La società nasce nel settembre del 2019, con l’obiettivo di aiutare le <b>aziende</b> a crescere sotto il <b>profilo digitale</b> e <b>tecnologico</b>, contestualizzando le scelte <b>strategiche</b> per migliorare o riorganizzare i processi di <b>business aziendali</b>.",
      about_content_2:
        "Il nostro fiore all’occhiello è l’<b>innovazione del core business</b> dei clienti, ai quali forniamo nuovi <b>strumenti digitali</b>, con lo scopo di aumentare consapevolezza dei propri mezzi, scoprire nuove <b>opportunità di mercato</b> e aumentare le <b>potenzialità di crescita del fatturato</b>.",
      about_content_3:
        "Abbiamo una forte specializzazione nel <b>cloud</b> e tutto ciò che può essere integrato con esso. Inoltre, l’azienda può vantare numerose <b>competenze certificate</b> su tecnologie <b>Microsoft</b>, grazie all’esperienza maturata in <b>progetti nazionali</b> ed <b>internazionali</b> seguiti nel corso degli anni, all’interno di settori e contesti di <b>mercato eterogenei</b>.",
      about_subtitle_2: "DINAMICITA' E FLESSIBILITA'",
      about_content_4:
        "A differenza di molte altre realtà, <b>AM Innovation</b> ha scelto di strutturarsi in modo snello e competitivo, facendo dello <b>smart-working</b> la sua caratteristica principale, vantando un ottimo livello di <b>dinamicità</b> e <b>flessibilità</b> nella gestione dei <b>progetti</b> e delle esigenze dei clienti.",
      about_content_5:
        "Il nostro cliente è l’Impresa che ha raggiunto una <b>visione imprenditoriale</b> volta al <b>futuro</b>, decisa a sposare il <b>cambiamento</b> e pronta ad affrontare un percorso di <b>trasformazione digitale</b> per migliorare il proprio <b>core business</b>.",
    },

    philosophy: {
      philosophy_title: "FILOSOFIA",
      philosophy_subtitle: "VISION",
      philosophy_subtitle_child: "UN MODELLO INNOVATIVO",
      philosophy_content_1:
        "AM Innovation è un’azienda <b>moderna</b> e aperta alle <b>novità</b>, di cui si fa portavoce con i propri clienti e collaboratori. Farne parte significa iniziare un <b>percorso di carriera</b> che concede a tutti l’opportunità di proporre <b>iniziative di business personali</b> e l’utilizzo di <b>nuove tecnologie</b>.",
      philosophy_content_2:
        "Abbiamo sempre un occhio di riguardo nei confronti di <b>investimenti in ricerca e sviluppo</b>, poiché le riteniamo attività fondamentali per la realizzazione dei prodotti che vogliamo sviluppare e lanciare sul <b>mercato</b>.",
      philosophy_subtitle_child_2: "ACADEMY, CORSI E SOFT SKILL",
      philosophy_content_10:
        "Allo stesso modo, portiamo avanti con successo costanti <b>collaborazioni</b> con <b>scuole</b> e aziende, organizzando <b>Academy</b> strutturate insieme ad altre realtà e integrando, quando e dove possibile, i <b>programmi scolastici</b>: riteniamo non vi sia investimento migliore di quello sul futuro dei <b>giovani</b>.",
      philosophy_content_11:
        "Poiché crediamo fermamente nell’importanza della <b>formazione</b>, abbiamo deciso di predisporne una <b>professionale</b> interna continua, con attività finalizzate al conseguimento di <b>certificazioni tecniche</b> e/o di tipo <b>manageriale</b>, in base al percorso che si sceglie di intraprendere. Il tutto, mantenendo un focus importante sulle <b>soft skills</b>, capacità indispensabili per <b>crescere</b> umanamente oltre che professionalmente.",
      philosophy_subtitle_2: "MISSION",
      philosophy_subtitle_child_3: "RISULTATI CERTIFICATI E MISURABILI",
      philosophy_content_5:
        "I tre concetti imprescindibili che ci contraddistinguono dai nostri <b>competitor</b> sono: la <b>creazione del valore</b>, la <b>formazione certificata</b> e l’<b>innovazione tecnologica</b>.",
      philosophy_content_6:
        "La creazione del valore è ciò che viene percepito dal cliente, in termini di <b>benefici</b> ricevuti e <b>costi sostenuti</b>, ovvero <b>risultati</b> concreti e soprattutto <b>misurabili</b>.",
      philosophy_content_7:
        "La formazione certificata è un segno distintivo di qualità e <b>competenza</b> per il cliente, oltre che motivo di orgoglio per l’azienda, che accresce notevolmente il <b>valore</b> del proprio personale e dell’affidabilità nel rispettare <b>impegni</b> e <b>responsabilità</b>.",
      philosophy_content_8:
        "L’innovazione tecnologica è la nostra <b>offerta</b>, ovvero la proposta di <b>soluzioni</b> tecnologicamente avanzate sulla base delle <b>esigenze</b> del cliente per <b>innovare</b>, e di conseguenza <b>migliorare</b>, l’<b>operatività aziendale</b> in tutti gli ambiti e in aree specifiche.",
      philosophy_subtitle_child_4: "LE PERSONE COME MIGLIOR INVESTIMENTO",
      philosophy_content_9:
        "<b>AM Innovation</b> si basa sulla <b>fiducia</b> e l’<b>investimento</b> nelle persone, l’infinita <b>passione</b> per l’innovazione e la <b>tecnologia</b> e l’incrollabile motivazione nel crescere e migliorarsi sempre.",
    },

    services: {
      services: "Servizi",
      services_subtitle:
        "I nostri servizi spaziano tra diversi ambiti e insieme costituiscono le fondamenta per un prodotto finale che vada a integrarsi e a migliorare le prestazioni delle aziende.",
      digital_trasformation:
        "Trasformazione digitale & innovazione tecnologica",
      digital_trasformation_content_1:
        "Questo servizio è progettato per guidare le aziende, in particolare le <b>piccole e medie imprese</b>, attraverso il processo di <b>digitalizzazione</b> e <b>innovazione</b>. Questo servizio offre un’analisi approfondita dei <b>processi di business</b>, identificando aree di intervento potenziali per introdurre <b>strumenti digitali personalizzati</b>, <b>ottimizzare i processi aziendali</b> e implementare la <b>raccolta e l’analisi dei dati</b>.",
      digital_trasformation_content_2:
        "Il nostro servizio si distingue per la sua capacità di <b>trasformare le aziende di prodotto in aziende di servizi</b> e viceversa, aprendo <b>nuovi orizzonti di mercato</b>. Attraverso l’adozione di <b>strumenti digitali</b> e <b>tecnologie innovative</b>, le aziende possono scoprire <b>nuove opportunità</b> per offrire servizi e/o prodotti, migliorando il proprio <b>business aziendale</b>.",
      digital_trasformation_content_3:
        "Un <b>elemento chiave</b> del nostro servizio è l’attenzione alle <b>persone</b>. Riconosciamo che le persone sono al <b>centro di qualsiasi business</b>: disegnano i processi, svolgono le operazioni necessarie e sono l’elemento di miglioramento e di consapevolezza. Per questo motivo, offriamo <b>corsi di formazione</b> su tematiche specifiche, con un focus importante sui <b>servizi cloud</b> e i <b>servizi di intelligenza artificiale</b>, per rendere più consapevoli i ruoli decisionali aziendali e il management.",
      digital_trasformation_content_4:
        "Il nostro servizio è <b>flessibile</b> e viene ritagliato <b>su misura</b> per il cliente, adattandosi al suo business. Il nostro <b>vantaggio competitivo</b> risiede nella <b>struttura del servizio</b> e nelle sue <b>modalità di erogazione</b>, all'interno delle quali le <b>interazioni</b> ed il <b>dialogo</b> con le persone sono alla base, rendendo più <b>semplice</b> il raggiungimento degli <b>obiettivi di risultato</b> condivisi insieme.",
      digital_trasformation_content_5:
        "La trasformazione digitale è un <b>processo continuo</b> che richiede <b>monitoraggio</b> e <b>miglioramenti costanti</b>.",

      technical_consultancy: "Consulenza Specializzata",
      technical_consultancy_content_1:
        "Questo è un servizio unico e personalizzato che offre alle aziende di tutte le dimensioni e settori la possibilità di accedere a <b>competenze specialistiche</b> su ruoli aziendali e argomenti specifici.",
      technical_consultancy_content_2:
        "Il nostro servizio si distingue per <b>due funzionalità principali</b>:",
      technical_consultancy_content_3:
        "<b>Innovation Manager as a Service</b>: questo tipo di ruolo <b>guida il cambiamento</b> e migliora la gestione aziendale attraverso il <b>progresso tecnologico e innovativo</b>. L'Innovation Manager è un professionista specializzato in ambito digitale, in grado di realizzare <b>progetti di accelerazione tecnologica</b> e guidare le aziende nel percorso verso la <b>digitalizzazione</b>.",
      technical_consultancy_content_4:
        "<b>Consulenza tecnica “Time & Material”</b>: forniamo consulenza tecnica con <b>profili professionali specifici</b> dedicati per un periodo di tempo concordato con il cliente. Questo permette alle aziende di avere accesso a competenze specialistiche quando ne hanno bisogno.",

      software_development: "Sviluppo Software e Progetti Avanzati",
      software_development_content_1:
        "Questo servizio è un’offerta unica che combina l’esperienza tecnica con una profonda comprensione delle esigenze del cliente. Parte del servizio consente di realizzare e sviluppare <b>software su misura</b> per le esigenze del cliente, orientati a <b>soluzioni cloud</b>. Le soluzioni offerte possono comprendere anche <b>scenari ibridi</b> (on-premise e cloud integrati), offrendo una “<b>soluzione chiavi in mano</b>”.",
      software_development_content_2:
        "I “progetti avanzati” coinvolgono tecnologie all’avanguardia come l’<b>intelligenza artificiale</b> applicata al business aziendale del cliente, nello specifico utilizzo dei servizi cloud dedicati all’intelligenza artificiale e sviluppo di <b>co-piloti personalizzati</b>, ritagliati su misura delle richieste del cliente.",
      software_development_content_3:
        "A differenza dei classici servizi di sviluppo software personalizzato, il nostro servizio coinvolge in modo attivo il cliente, integrandolo nel <b>processo di sviluppo software</b> per ottenere feedback con tempi ridotti, dando visibilità del lavoro svolto. Con iterazioni strutturate e ripetitive (processo Agile) è possibile rilasciare nuovi aggiornamenti e/o versioni del software a disposizione del cliente in tempi brevi.",

      interface_design_title:
        "Design Grafico e Progettazione Esperienza Utente",
      interface_design_content_1:
        "Questo servizio innovativo offre alle aziende la possibilità di creare <b>prototipi grafici</b> a bassa ed alta fedeltà per <b>definire il processo operativo</b> dell’utente, migliorandone l’<b>usabilità</b>, la <b>semplicità di utilizzo</b> e l’<b>intuitività</b>.",
      interface_design_content_2:
        "Considerata la natura del servizio <b>design-oriented</b>, viene data precedenza all’aspetto operativo per concentrarsi sull’<b>esperienza utente</b> e, successivamente, all’aspetto puramente grafico. Questo approccio consente di realizzare una chiara <b>comunicazione visiva</b> a livello grafico ed esperienziale che coinvolge l’utente e lo guida nelle sue attività operative.",
      interface_design_content_3:
        "Tale servizio può essere applicato a soluzioni <b>web, mobile e per dispositivi HMI industriali</b>, rendendolo estremamente versatile e adattabile a una vasta gamma di applicazioni.",
      interface_design_content_4:
        "Il valore aggiunto di questo servizio è consentire al cliente di <b>toccare con mano</b> quale sarà il risultato dell’esperienza utente e del design grafico della soluzione prima del suo reale sviluppo. In questo modo, il cliente <b>partecipa attivamente</b> a tutte le fasi di prototipazione, arricchendo la conoscenza operativa e migliorando il risultato finale con continui feedback e scambi di informazione.",
    },

    technologies: {
      technologies_title: "Tecnologie usate",
    },

    contact: {
      title: "Contatti",
      name: "Il tuo nome",
      email: "La tua E-mail",
      subject: "Titolo",
      message: "Messaggio",
      send_message: "Invia Messaggio",
      location: "Indirizzo",
      phone: "Telefono",
    },
  },

  "en-GB": {
    navbar: {
      about_us: "Company",
      services: "Services",
      philosophy: "Philosophy",
      technologies: "Technologies",
      partners: "Our Customers",
      contact_us: "Contacts",
    },

    tooltip: {
      language: "Change language",
      theme: "Change theme",
    },

    figcaption: {
      tigullioWeb: "Web UX/UI developed for Tigullio Carni",
      tigullioMobile: "Mobile UX/UI developed for Tigullio Carni",
      technosistemHMI: "HMI UX/UI developed for Tecnosystem",
    },

    header: {
      header_subtitle:
        "We turn the complexity of business operations into digital added value. We are always looking for new challenges.",
      header_quote: "The foundations of our company make us who we are.",
    },

    ux_ui: {
      ux_ui_title: "THE ESSENTIAL ROLE OF USER EXPERIENCE",
      ux_ui_content_1:
        "The importance of using a UX/UI (User Experience/User Interface) service for the development of a digital application is crucial for several reasons:",
      ux_ui_content_2:
        "<b>Improved User Experience</b>: A good <b>UX/UI</b> design can significantly improve the <b>user experience</b>. An intuitive and easy-to-use interface can make the application more enjoyable and less frustrating for the user.",
      ux_ui_content_3:
        "<b>Increased Engagement</b>: An effective UX/UI design can increase <b>user engagement</b>. If a user finds an application easy to use and enjoyable, they are more likely to use it regularly and for longer periods of time.",
      ux_ui_content_4:
        "<b>Higher Conversion Rates</b>: A good UX/UI design can lead to <b>higher conversion rates</b>. If a user can easily navigate through an application and perform desired actions, they are more likely to take actions that lead to conversions, such as making a purchase or signing up for a service.",
      ux_ui_content_5:
        "<b>Differentiation from Competition</b>: High-quality UX/UI design can help an application stand out from the <b>competition</b>. If an application offers a superior user experience compared to competing applications, it can become the preferred choice of users.",
      ux_ui_content_6:
        "<b>Long-Term Cost Reduction</b>: Investing in good UX/UI design at the beginning of application development can reduce <b>long-term costs</b>. A well-thought-out design can reduce the need for costly changes or corrections later on.",
    },

    about: {
      about_subtitle_1: "TECHNOLOGICAL INNOVATION",
      about_content_1:
        "The company was founded in September 2019, with the aim of helping <b>companies</b> to grow from a <b>digital and technological point of view</b>, contextualizing <b>strategic</b> choices to improve or reorganize <b>company business processes</b>.",
      about_content_2:
        "Our flagship is the <b>innovation of the core business</b> of customers, to whom we provide new <b>digital tools</b>, with the aim of increasing awareness of their means, discovering new <b>market opportunities</b>, and increasing the potential for <b>revenue growth</b>.",
      about_content_3:
        "We have a strong specialization in the <b>cloud</b> and everything that can be integrated with it. In addition, our company can boast numerous <b>certified expertise</b> on Microsoft technologies, thanks to the experience gained in national and international projects over the years, within <b>heterogeneous sectors and market contexts</b>.",
      about_subtitle_2: "DYNAMISM AND FLEXIBILITY",
      about_content_4:
        "Unlike many other companies, <b>AM Innovation</b> has chosen to structure itself in a lean and competitive way, making <b>smart-working</b> its main feature, boasting an excellent level of <b>dynamism and flexibility</b> in managing projects and customer needs.",
      about_content_5:
        "Our client is the company that has achieved an <b>entrepreneurial vision</b> aimed at the <b>future</b>, determined to embrace <b>change</b> and ready to face a digital <b>transformation</b> path to improve its <b>core business</b>.",
    },

    philosophy: {
      philosophy_title: "PHILOSOPHY",
      philosophy_subtitle: "VISION",
      philosophy_subtitle_child: "AN INNOVATIVE MODEL",
      philosophy_content_1:
        "AM Innovation is a <b>modern</b> company open to <b>new horizonts</b>, which it is the spokesperson for its customers and collaborators. Being part of it means starting a <b>career path</b> that gives everyone the opportunity to propose <b>personal business initiatives</b> and the use of <b>new technologies</b>.",
      philosophy_content_2:
        "We always have an eye on <b>investments in research and development</b>, as we consider them fundamental activities for the realization of the <b>products</b> we want to develop and launch on the <b>market</b>.",
      philosophy_subtitle_child_2: "ACADEMY, COURSES AND SOFT SKILLS",
      philosophy_content_10:
        "In the same way, we successfully carry out constant <b>collaborations</b> with <b>schools</b> and companies, organizing structured Academies together with other realities and integrating <b>school programs</b>, when and where possible: we believe there is no better <b>investment</b> than that in the <b>future of young people</b>.",
      philosophy_content_11:
        "Since we firmly believe in the importance of <b>training</b>, we have decided to set up an internal <b>professional</b> one, with activities aimed at achieving <b>technical</b> and/or <b>managerial certifications</b>, based on the path you choose to undertake. All this, while maintaining an important focus on <b>soft skills</b>, indispensable skills to <b>grow</b> humanly as well as professionally.",
      philosophy_subtitle_2: "MISSION",
      philosophy_subtitle_child_3: "CERTIFIED AND MEASURABLE RESULTS",
      philosophy_content_5:
        "The three essential concepts that distinguish us from our <b>competitors</b> are: the <b>creation</b> of <b>value</b>, <b>certified training</b> and <b>technological innovation</b>.",
      philosophy_content_6:
        "The creation of value is what is perceived by the customer, in terms of <b>benefits</b> received and <b>cost effectiveness</b>, that are concrete and above all, <b>measurable results</b>.",
      philosophy_content_7:
        "Certified training is a hallmark of quality and <b>competence</b> for the customer, as well as a source of pride for the company, which greatly increases the value of its <b>staff</b> and the reliability in respecting <b>commitments</b> and <b>responsibilities</b>.",
      philosophy_content_8:
        "Technological innovation is our <b>offer</b>, the proposal of technologically advanced <b>solutions</b> based on the customer’s <b>needs</b> to innovate, and consequently <b>improve</b>, the <b>company’s operations</b> in all areas and specific ones.",
      philosophy_subtitle_child_4: "PEOPLE AS THE BEST INVESTMENT",
      philosophy_content_9:
        "<b>AM Innovation</b> is based on <b>trust</b> and <b>investment</b> in people, the infinite <b>passion</b> for innovation and <b>technology</b> and the unwavering motivation to always grow and improve.",
    },

    services: {
      services: "Services",
      services_subtitle:
        "Our services range from different areas and together they form the foundation for a final product that integrates and improves the performance of companies.",
      digital_trasformation:
        "Digital transformation & technological innovation",
      digital_trasformation_content_1:
        "This service is designed to guide companies, particularly <b>small and medium enterprises</b>, through the process of <b>digitization</b> and <b>innovation</b>. This service provides an in-depth analysis of <b>business processes</b>, identifying potential areas of intervention to introduce <b>customized digital tools</b>, <b>optimize business processes</b>, and implement <b>data collection and analysis</b>.",
      digital_trasformation_content_2:
        "Our service stands out for its ability to <b>transform product companies into service companies</b> and vice versa, opening <b>new market horizons</b>. Through the adoption of <b>digital tools</b> and <b>innovative technologies</b>, companies can discover <b>new opportunities</b> to offer services and/or products, improving their own <b>business</b>.",
      digital_trasformation_content_3:
        "A <b>key element</b> of our service is the attention to <b>people</b>. We recognize that people are at the <b>center of any business</b>: they design the processes, carry out the necessary operations, and are the element of improvement and awareness. For this reason, we offer <b>training courses</b> on specific topics, with a significant focus on <b>cloud services</b> and <b>artificial intelligence services</b>, to make business decision-making roles and management more aware.",
      digital_trasformation_content_4:
        "Our service is <b>flexible</b> and is tailored <b>to measure</b> for the customer, adapting to their business. Our <b>competitive advantage</b> lies in the <b>structure of the service</b> and its <b>delivery methods</b>, within which <b>interactions</b> and <b>dialogue</b> with people are fundamental, making it <b>easier</b> to achieve the <b>result objectives</b> shared together.",
      digital_trasformation_content_5:
        "Digital transformation is a <b>continuous process</b> that requires <b>monitoring</b> and <b>constant improvements</b>.",
      technical_consultancy: "Specialised consultancy",
      technical_consultancy_content_1:
        "This is a unique and personalized service that offers companies of all sizes and sectors the opportunity to access <b>specialized skills</b> on business roles and specific topics.",
      technical_consultancy_content_2:
        "Our service stands out for its <b>two main features</b>:",
      technical_consultancy_content_3:
        "<b>Innovation Manager as a Service</b>: this type of role <b>guides change</b> and improves business management through <b>technological and innovative progress</b>. The Innovation Manager is a professional specialized in the digital field, capable of implementing <b>technological acceleration projects</b> and guiding companies on the path to <b>digitization</b>.",
      technical_consultancy_content_4:
        "<b>“Time &amp; Material” Technical Consultancy</b>: we provide technical consultancy with <b>specific professional profiles</b> dedicated for a period of time agreed with the customer. This allows companies to have access to specialized skills when they need them.",
      software_development: "Software Development and Advanced Projects",
      software_development_content_1:
        "This service is a unique offer that combines technical experience with a deep understanding of the customer’s needs. Part of the service allows to create and develop <b>custom software</b> for the customer’s needs, oriented to <b>cloud solutions</b>. The solutions offered can also include <b>hybrid scenarios</b> (integrated on-premise and cloud), offering a <b>“turnkey solution”</b>.",
      software_development_content_2:
        "The “advanced projects” involve cutting-edge technologies such as <b>artificial intelligence</b> applied to the customer’s business, specifically the use of cloud services dedicated to artificial intelligence and the development of <b>customized co-pilots</b>, tailored to the customer’s requests.",
      software_development_content_3:
        "Unlike traditional custom software development services, our service actively involves the customer, integrating them into the <b>software development process</b> to obtain feedback in reduced times, giving visibility of the work done. With structured and repetitive iterations (Agile process), it is possible to release new updates and/or versions of the software available to the customer in a short time.",
      software_development_content_4: "",
      interface_design_title: "Graphic and User Experience Design",
      interface_design_content_1:
        "This innovative service offers companies the opportunity to create <b>graphic prototypes</b> of low and high fidelity to <b>define the operational process</b> of the user, improving its <b>usability</b>, <b>ease of use</b>, and <b>intuitiveness</b>.",
      interface_design_content_2:
        "Given the <b>design-oriented</b> nature of the service, priority is given to the operational aspect to focus on the <b>user experience</b> and, subsequently, on the purely graphic aspect. This approach allows for clear <b>visual communication</b> at the graphic and experiential level that involves the user and guides them in their operational activities.",
      interface_design_content_3:
        "This service can be applied to <b>web, mobile, and industrial HMI device</b> solutions, making it extremely versatile and adaptable to a wide range of applications.",
      interface_design_content_4:
        "The added value of this service is to allow the customer to <b>experience firsthand</b> what the result of the user experience and the graphic design of the solution will be before its actual development. In this way, the customer <b>actively participates</b> in all stages of prototyping, enriching operational knowledge and improving the final result with continuous feedback and information exchange.",
    },

    technologies: {
      technologies_title: "Our Technologies",
    },

    contact: {
      title: "Contact us",
      name: "Your name",
      email: "Your email",
      subject: "Subject",
      message: "Message",
      send_message: "Send message",
      location: "Location",
      phone: "Phone",
    },
  },
};

export { localizedTexts };
