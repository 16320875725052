import { createApp } from "vue";
import App from "./App.vue";
import routes from "./route/routes.js";
import BootstrapVueNext from "bootstrap-vue-next";
import VueWriter from "vue-writer";
import { createI18n } from "vue-i18n";
import { localizedTexts } from "@/translation/localizedTexts";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import "@/assets/scss/style.scss";
// import '@/assets/css/style.css'
import "@/assets/css/materialdesignicons.min.css";
import "@/assets/css/boxicons.min.css";

const i18n = createI18n({
  silentTranslationWarn: true,
  warnHtmlMessage: false, // disable warning HTML in message
  legacy: false,
  locale: "it-IT", // set locale
  fallbackLocale: "it-IT",
  messages: localizedTexts,
});

createApp(App)
  .use(BootstrapVueNext)
  .use(routes)
  .use(VueWriter)

  .use(i18n)
  .mount("#app");
